import React from "react";
import { useCountUp } from "react-countup";

const SectionTeam = () => {
  const countUpDesigner = React.useRef(null);
  const countUpDeveloper = React.useRef(null);
  const countUpContributor = React.useRef(null);

  useCountUp({
    ref: countUpDesigner,
    end: 26,
    enableScrollSpy: true,
    suffix: " Designer",
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpDeveloper,
    end: 46,
    enableScrollSpy: true,
    suffix: " Developer",
    scrollSpyOnce: true,
  });
  useCountUp({
    ref: countUpContributor,
    end: 33,
    enableScrollSpy: true,
    suffix: " Contributor",
    scrollSpyOnce: true,
  });

  return (
    <section className="landing-team">
      <div className="landing-container">
        <h2 className="landing-h2 text-center">
          Team’s growth is steadily improving
        </h2>
        <div className="landing-team__inner">
          <div className="landing-team__inner__left">
            <p>
              Butuh waktu yang tidak sebentar disertai dengan kesabaran ekstra
              untuk membangun kepercayaan Designer dan Developer terhadap Legion
              Design System. Salah satu caranya adalah dengan pembaruan aset,
              content, dan update melalui sistem versioning.
            </p>

            <p>
              Gencarnya sosialisasi tentang Legion Design System juga menjadi
              alasan utama yang membuat Designer dan Developer menjadi
              Maintainer Legion. Maintainer, adalah orang orang yang percaya dan
              mampu mengimplementasi Legion ke masing masing Tribe.
            </p>

            <p>
              Dari ratusan digital talent yang ada di Direktorat DBT, kami
              berhasil membuat rasio tim Legion Design System adalah 60:40.
              Dengan catatan, saat ini hanya 3 talent yang bekerja secara
              fulltime di Legion. Rasio tersebut adalah rasio terstandarisasi
              untuk pengembangan sebuah design system.
            </p>
          </div>
          <div className="landing-team__inner__right">
            <h3 ref={countUpDesigner} className="landing-h3" />
            <p>Maintainer Legion Design System</p>
            <h3 ref={countUpDeveloper} className="landing-h3" />
            <p>Maintainer Legion Design System</p>
            <h3 ref={countUpContributor} className="landing-h3" />
            <p>
              UI Designer, UX Designer, Researcher, Writer, Visual Designer &
              Developer.
            </p>
          </div>
        </div>
      </div>
      <div className="landing-team__decoration"></div>
    </section>
  );
};

export default SectionTeam;
